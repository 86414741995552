var Home = {};

Home.Init = function () {
    this.Bindings();
    this.NewsHover();
};

Home.Bindings = function () {
    $('.filter-option').on('click', Home.SwitchNews);
};

Home.SwitchNews = function (e) {
    var type = $(this).data('type');
    var active = $('.filter-option.active');
    if (type === active.data('type')) {
        return false;
    }
    $('#' + active.data('type')).hide();
    $('#' + type).show();
    $('.filter-option.active').removeClass("active");
    $('.filter-option[data-type=' + type + ']').addClass('active');

    $('.button.white.arrow-btn[data-type=' + active.data('type') + ']').hide();
    $('.button.white.arrow-btn[data-type=' + type + ']').show();

    /* Calculate NewsHover */
    Home.NewsHover();

    return false;
};

Home.NewsHover = function() {
    $('.news-listing .right-column .news-item').each(function() {
        var bottom = 0;
        if ($(this).find('p').length > 0) {
            bottom = parseInt($(this).find('p').outerHeight(true)); 
        }
        
        if ($(this).find('.learn').length > 0) {
            bottom = bottom + parseInt($(this).find('.learn').outerHeight(true));
        }

        $(this).find('.content').css('bottom','-'+bottom - 5 +'px');
    });
};

$(window).on('load', function() {
    Home.Init();
});

$(window).on('resize', function() {
    Home.NewsHover();
});